import {getClubStates} from "../api/clubStateApi";

export const GET_ALL_CLUB_STATES = 'GET_ALL_CLUB_STATES';

export const getAllClubStates = () => dispatch => {
    getClubStates().then(res => {
        dispatch({
            type: GET_ALL_CLUB_STATES,
            payload: res.data
        })
    })
};