import {getSalutations} from "../api/salutationApi";

export const GET_ALL_SALUTATIONS = 'GET_ALL_SALUTATIONS';

export const getAllSalutations = () => dispatch => {
    getSalutations().then(res => {
        dispatch({
            type: GET_ALL_SALUTATIONS,
            payload: res.data
        })
    })
};