import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import PropTypes from 'prop-types';

// Example of user roles: ['GUEST', 'USER', 'ADMIN'];
export const ROLE_ADMIN = 'ADMIN';
export const ROLE_USER = 'USER';
export const ROLE_GUEST = 'GUEST';

function AuthGuard({roles, children}) {
    const session = useSelector((state) => state.session);
    const history = useHistory();

    useEffect(() => {
        if (!session.loggedIn || !session.user) {
            history.push('/auth/login');
            return;
        }

        if (!roles.includes(session.user.role.toUpperCase())) {
            history.push('/errors/error-403');
        }
    }, [history, roles, session.loggedIn, session.user]);

    return <>{children}</>;
}

AuthGuard.propTypes = {
    children: PropTypes.node,
    roles: PropTypes.array.isRequired
};

export default AuthGuard;
