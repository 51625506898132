import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { setLanguage } from 'src/utils/language';

const LanguageSwitcher = (props) => {
    
    const { i18n } = useTranslation();
    const languages = Object.keys(i18n.options.resources);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(languages.indexOf(i18n.language));

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
  
    const handleLangSelect = (lang, index) => {
        i18n.changeLanguage(lang);
        setSelectedIndex(index);
        setLanguage(lang);
        handleClose();
    };

    const handleClose = () => setAnchorEl(null);

    const menuItems = languages.map((lang, i) =>
        (<MenuItem key={i} onClick={() => handleLangSelect(lang, i)} selected={i === selectedIndex}>{lang.toUpperCase()}</MenuItem>)
    );

    return (
        <React.Fragment>
            <Button aria-controls="lang-menu" aria-haspopup="true" color="inherit" onClick={handleClick}>
                {i18n.language}
            </Button>
            <Menu
                    id="lang-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    >
                { menuItems }
            </Menu>
      </React.Fragment>
    );
};

export default LanguageSwitcher;
