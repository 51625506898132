import {applyMiddleware, compose, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
// import { createLogger } from 'redux-logger';
import rootReducer from 'src/reducers';

// const loggerMiddleware = createLogger();

const configureStore = (preloadedState = {}) => {
    const middleware = [thunkMiddleware]; // loggerMiddleware
    const middlewareEnhancer = composeWithDevTools(
        applyMiddleware(...middleware)
    );

    const enhancers = [middlewareEnhancer];
    const composedEnhancers = compose(...enhancers);

    return createStore(rootReducer, preloadedState, composedEnhancers);
};
export default configureStore
