import * as actionTypes from 'src/actions';

const initialState = {
    allLanguages: []
};


const languageReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionTypes.GET_ALL_LANGUAGES: {
            return {...state, allLanguages: action.payload}
        }
        default:
            return state;
    }
};


export default languageReducer;