/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import PeopleIcon from '@material-ui/icons/People';
import ContactsIcon from '@material-ui/icons/Contacts';
import ExtensionIcon from '@material-ui/icons/Extension';
import SettingsIcon from '@material-ui/icons/Settings';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import { FaBarcode, FaUsersCog } from "react-icons/fa";
import { AiOutlineStock } from "react-icons/ai";
import { TiDocumentText } from "react-icons/ti";
import LabelIcon from '@material-ui/icons/Label';
import { ROLE_ADMIN, ROLE_USER } from 'src/components/AuthGuard';

export default [
    {
        subheader: 'User Management',
        roles: [ROLE_ADMIN],
        items: [
            {
                title: 'Users',
                href: '/management/user',
                icon: PeopleIcon
            }
        ]
    },
    {
        subheader: 'Customer Management',
        items: [
            {
                title: 'Customers',
                href: '/management/customer',
                icon: ContactsIcon,
            }
        ]
    },
    {
        subheader: 'Article Management',
        items: [
            {
                title: 'Articles',
                href: '/management/article',
                icon: ExtensionIcon
            },
            {
                title: 'Stock quantity',
                href: '/management/article/stock-quantity/stock',
                icon: AiOutlineStock,
                items: [
                    {
                        title: 'By Stock',
                        href: '/management/article/stock-quantity/stock'
                    },
                    {
                        title: 'By Article',
                        href: '/management/article/stock-quantity/article'
                    },
                ]
            },
            {
                title: 'Settings',
                href: '/management/article/settings',
                icon: SettingsIcon,
                items: [
                    {
                        title: 'Stock',
                        href: '/management/article/settings/stock'
                    },
                    {
                        title: 'Charge',
                        href: '/management/article/settings/charge'
                    },
                    {
                        title: 'Charge to barcode',
                        href: '/management/article/settings/charge-to-barcode'
                    },
                    {
                        title: 'Article Group',
                        href: '/management/article/settings/article-group'
                    },
                    {
                        title: 'Sales Category',
                        href: '/management/article/settings/sales-category'
                    },
                    {
                        title: 'Size',
                        href: '/management/article/settings/size'
                    }
                ]
            }
        ]
    },
    {
        subheader: 'Order Management',
        items: [
            {
                title: 'Document',
                href: '/management/document/list',
                icon: TiDocumentText,
            },
            {
                title: 'Order',
                href: '/management/order/list',
                icon: LabelIcon,
            },
            {
                title: 'Settings',
                href: '/management/settings',
                icon: SettingsIcon,
                items: [
                    {
                        title: 'Type',
                        href: '/management/order/settings/type'
                    },
                    {
                        title: 'Payment Method',
                        href: '/management/order/settings/payment-method'
                    }
                ]
            }
        ]
    }
];
