import {combineReducers} from 'redux';
import sessionReducer from './sessionReducer';
import salutationReducer from "./salutationReducer";
import languageReducer from "./languageReducer";
import advertisementGroupReducer from "./advertisementGroupReducer";
import snackbarReducer from "./snackbarReducer";
import clubStateReducer from "./clubStateReducer";

const rootReducer = combineReducers({
    session: sessionReducer,
    salutation: salutationReducer,
    language: languageReducer,
    advertisementGroup: advertisementGroupReducer,
    snackbar: snackbarReducer,
    clubState: clubStateReducer
});

export default rootReducer;
