import * as actionTypes from 'src/actions';

const initialState = {
    allSalutations: []
};


const salutationReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionTypes.GET_ALL_SALUTATIONS: {
            return {...state, allSalutations: action.payload}
        }
        default:
            return state;
    }
};


export default salutationReducer;