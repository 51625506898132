import * as actionTypes from 'src/actions';

const initialState = {
    allClubStates: []
};


const clubStateReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionTypes.GET_ALL_CLUB_STATES: {
            return {...state, allClubStates: action.payload}
        }
        default:
            return state;
    }
};


export default clubStateReducer;