import mock from "../utils/mock";
import faker from 'faker';

mock.onGet('/api/management/address/list').reply(200, {
    items: [...Array(35).keys()]
        .map(() => ({
            id: faker.random.number(),
            name: faker.company.companyName(),
            street: faker.address.streetAddress(),
            postalLocation: `${faker.address.zipCode()}`
        }))
});