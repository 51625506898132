import {getLanguages} from "../api/languageApi";

export const GET_ALL_LANGUAGES = 'GET_ALL_LANGUAGES';

export const getAllLanguages = () => dispatch => {
    getLanguages().then(res => {
        dispatch({
            type: GET_ALL_LANGUAGES,
            payload: res.data
        })
    })
};