import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {AppBar, Toolbar} from '@material-ui/core';
import LanguageSwitcher from 'src/components/LanguageSwitcher';

const useStyles = makeStyles(() => ({
    root: {
        boxShadow: 'none',
    },
    flexGrow: {
        flexGrow: 1
    },
}));

function Topbar({className, ...rest}) {
    const classes = useStyles();

    return (
        <AppBar{...rest} className={clsx(classes.root, className)} color="primary">
            <Toolbar>
                <RouterLink to="/">
                    <img alt="Logo" width="100px" src="/images/logos/fasolit-logo.png"/>
                </RouterLink>
                <div className={classes.flexGrow}/>
                <LanguageSwitcher />
            </Toolbar>
        </AppBar>
    );
}

Topbar.propTypes = {
    className: PropTypes.string
};

export default Topbar;
