import React, {Suspense, useState} from 'react';
import {renderRoutes} from 'react-router-config';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {LinearProgress} from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import AuthGuard, { ROLE_ADMIN, ROLE_USER } from 'src/components/AuthGuard';

const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: '100vh',
        display: 'flex',
        '@media all and (-ms-high-contrast:none)': {
            height: 0 // IE11 fix
        }
    },
    content: {
        paddingTop: 64,
        flexGrow: 1,
        maxWidth: '100%',
        overflowX: 'hidden',
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 256
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: 56
        }
    }
}));

function Dashboard({route}) {
    const classes = useStyles();
    const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

    return (
        <>
            <AuthGuard roles={[ ROLE_ADMIN, ROLE_USER ]}>
                <TopBar onOpenNavBarMobile={() => setOpenNavBarMobile(true)}/>
                <NavBar
                    onMobileClose={() => setOpenNavBarMobile(false)}
                    openMobile={openNavBarMobile}
                />
                <div className={classes.container}>
                    <div className={classes.content}>
                        <Suspense fallback={<LinearProgress/>}>
                            {renderRoutes(route.routes)}
                        </Suspense>
                    </div>
                </div>
            </AuthGuard>
        </>
    );
}

Dashboard.propTypes = {
    route: PropTypes.object
};

export default Dashboard;
