import * as actionTypes from 'src/actions';

// TODO: remove later, now using as a refference
// const initialState = {
//     loggedIn: true,
//     user: {
//         first_name: 'Giacinto',
//         last_name: 'Pettorino',
//         email: 'giacinto.pettorino@fasolit.ch',
//         avatar: '/images/avatars/avatar_11.png',
//         bio: 'admin',
//         role: 'ADMIN' // ['GUEST', 'USER', 'ADMIN']
//     }
// };

const initialState = {
    loggedIn: false,
    user: {
        role: 'GUEST'
    },
};

const sessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SESSION_LOGIN: {
            return {
                ...state,
                loggedIn: true,
                user: action.payload,
            };
        }

        case actionTypes.SESSION_LOGOUT: {
            const userUpdates = action.payload || {};
            return {
                ...state,
                loggedIn: false,
                user: {
                    role: 'GUEST',
                    ...userUpdates
                }
            };
        }

        default: {
            return state;
        }
    }
};

export default sessionReducer;
