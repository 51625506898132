import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkAuth } from './actions';
import PropTypes from 'prop-types';


function RouteBuilder({children}) {
    const dispatch = useDispatch();
    const session = useSelector((state) => state.session);
    const [loaded, setLoading] = useState(false)

    useEffect((user) => {
      if (!session.user.loaded && !loaded) {
        dispatch(checkAuth());
      } else {
        setLoading(true);
      }
    }, [session.user]);

    return <>{loaded && children}</>;
}

RouteBuilder.propTypes = {
  children: PropTypes.node,
};


export default RouteBuilder;
