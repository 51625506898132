import {getAdvertisementGroups} from "../api/advertisementGroupApi";

export const GET_ALL_ADVERTISEMENT_GROUPS = 'GET_ALL_ADVERTISEMENT_GROUPS';

export const getAllAdvertisementGroups = () => dispatch => {
    getAdvertisementGroups().then(res => {
        dispatch({
            type: GET_ALL_ADVERTISEMENT_GROUPS,
            payload: res.data
        })
    })
};