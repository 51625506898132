import * as actionTypes from 'src/actions';

const initialState = {
    allAdvertisementGroups: []
};


const advertisementGroupReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionTypes.GET_ALL_ADVERTISEMENT_GROUPS: {
            return {...state, allAdvertisementGroups: action.payload}
        }
        default:
            return state;
    }
};


export default advertisementGroupReducer;