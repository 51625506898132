import mock from "../utils/mock";
import faker from 'faker';

mock.onGet('/api/management/customer/list').reply(200, {
    items: [...Array(35).keys()]
        .map(() => ({
            id: faker.random.number(),
            salutation: faker.helpers.randomize(['Herr', 'Frau']),
            firstName: faker.name.firstName(undefined),
            lastName: faker.name.lastName(undefined),
            email: faker.internet.email(),
            mobile: faker.phone.phoneNumber(),
            fixnet: faker.phone.phoneNumber(),
            language: faker.helpers.randomize(['DE', 'FR', 'EN', 'RO']),
            address: faker.address.streetAddress(),
            club_number: faker.random.number(),
            club_since: faker.date.recent(),
            club_state: faker.helpers.randomize(['Pro', 'Max', 'Ultimate', 'Basic']),
            club_points: faker.random.number(),
            dsvgo_date: faker.date.recent(),
            birthdate: faker.date.past(),
            eShopNumber: faker.random.number(),
            advertisement_group: faker.helpers.randomize(['Beginner', 'Advanced', 'Senior']),
            isLead: faker.helpers.randomize(['True', 'False'])
        }))
});

mock.onGet('/api/management/customer/settings/country/list').reply(200, {
    items: [...Array(35).keys()]
        .map(() => ({
            shortName: faker.address.countryCode(),
            fullName: faker.address.country()
        }))
});
mock.onGet('/api/management/customer/settings/postal-location/list').reply(200, {
    items: [...Array(35).keys()]
        .map(() => ({
            id: faker.random.number(),
            zipCode: faker.address.zipCode(),
            city: faker.address.city(),
            country: faker.address.country()
        }))
});
mock.onGet('/api/management/customer/settings/salutation/list').reply(200, {
    items: [
        {
            name: 'Frau'
        },
        {
            name: 'Herr'
        }
    ]
});