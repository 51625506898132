/* eslint-disable react/no-multi-comp */
import React, {useEffect, useState} from 'react';
import {matchPath, useLocation} from 'react-router';
import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/styles';
import {Avatar, Badge, colors, Divider, Drawer, Hidden, Link, List, ListSubheader, Typography, Tooltip} from '@material-ui/core';
import v from 'voca';
import NavItem from 'src/components/NavItem';
import navConfig from './navConfig';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    mobileDrawer: {
        width: 256,
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)'
    },
    navigation: {
        overflow: 'auto',
        padding: theme.spacing(0, 2, 2, 2),
        flexGrow: 1
    },
    profile: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center'
    },
    badge: {
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
    },
    badgeDot: {
        height: 9,
        minWidth: 9
    },
    onlineBadge: {
        backgroundColor: colors.green[600]
    },
    awayBadge: {
        backgroundColor: colors.orange[600]
    },
    busyBadge: {
        backgroundColor: colors.red[600]
    },
    offlineBadge: {
        backgroundColor: colors.grey[300]
    },
    avatar: {
        cursor: 'pointer',
        width: 40,
        height: 40
    },
    details: {
        marginLeft: theme.spacing(2)
    },
    moreButton: {
        marginLeft: 'auto',
        color: colors.blueGrey[200]
    }
}));

const renderNavItems = ({items, subheader, key, roles, ...rest}, user, t) => {
    return (!roles || roles.includes(user.role.toUpperCase())) && (
    <List key={key}>
        {subheader && <ListSubheader disableSticky>{t(subheader)}</ListSubheader>}
        {items.reduce((acc, item) => reduceChildRoutes({acc, item, ...rest}, user, t), [])}
    </List>
)};

function reduceChildRoutes({acc, pathname, item, depth = 0}, user, t) {
    if (item.roles && !item.roles.includes(user.role)) {
        return acc;
    }

    if (item.items) {
        const open = matchPath(pathname, {
            path: item.href,
            exact: false
        });

        acc.push(
            <NavItem
                depth={depth}
                icon={item.icon}
                key={item.href}
                label={item.label}
                open={Boolean(open)}
                title={t(item.title)}>
                {renderNavItems({
                    depth: depth + 1,
                    pathname,
                    items: item.items,
                    roles: item.roles,
                }, user, t)}
            </NavItem>
        );
    } else {
        acc.push(
            <NavItem
                depth={depth}
                href={item.href}
                icon={item.icon}
                key={item.href}
                label={item.label}
                title={t(item.title)}
            />
        );
    }

    return acc;
}

const NavBar = ({
                    openMobile,
                    onMobileClose,
                    className,
                    ...rest
                }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const location = useLocation();
    const session = useSelector((state) => state.session);
    const [status, setStatus] = useState('online');
    const handleStatusToggle = () => {
        const statusSeq = {
            online: 'away',
            away: 'busy',
            busy: 'offline',
            offline: 'online'
        };

        setStatus((prevStatus) => statusSeq[prevStatus]);
    };

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line
    }, [location.pathname]);

    const content = (
        <div{...rest} className={clsx(classes.root, className)}>
            <nav className={classes.navigation}>
                {navConfig.map((list) => renderNavItems({
                    items: list.items,
                    subheader: list.subheader,
                    pathname: location.pathname,
                    key: list.subheader,
                    roles: list.roles,
                }, session.user, t))}
            </nav>
            <Divider className={classes.divider}/>
            <div className={classes.profile}>
                <Badge overlap="circle"
                       anchorOrigin={{
                           vertical: 'bottom',
                           horizontal: 'right',
                       }}
                       classes={{
                           dot: classes.badgeDot,
                           badge: clsx({
                               [classes.badge]: true,
                               [classes.onlineBadge]: status === 'online',
                               [classes.awayBadge]: status === 'away',
                               [classes.busyBadge]: status === 'busy',
                               [classes.offlineBadge]: status === 'offline'
                           })
                       }}
                       variant="dot">
                    <Avatar alt="Person"
                            onClick={handleStatusToggle}
                            className={classes.avatar}>
                        {`${v.first(session.user.first_name)}${v.first(session.user.last_name)}`}
                    </Avatar>
                </Badge>
                <div className={classes.details}>
                    <Link component={RouterLink}
                          to="/profile"
                          variant="h5"
                          color="textPrimary"
                          underline="none">
                        {`${session.user.first_name} ${session.user.last_name}`}
                    </Link>
                    <Tooltip title={session.user.companyId || ''}>
                        <Typography variant="body2">{session.user.companyName}</Typography>
                    </Tooltip>
                </div>
            </div>
        </div>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer anchor="left"
                        classes={{
                            paper: classes.mobileDrawer
                        }}
                        onClose={onMobileClose}
                        open={openMobile}
                        variant="temporary">
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    anchor="left"
                    classes={{
                        paper: classes.desktopDrawer
                    }}
                    open
                    variant="persistent">
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

NavBar.propTypes = {
    className: PropTypes.string,
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

export default NavBar;
