import React from 'react';
import {Router} from 'react-router-dom';
import {renderRoutes} from 'react-router-config';
import {createBrowserHistory} from 'history';
import MomentUtils from '@date-io/moment';
import {Provider as StoreProvider} from 'react-redux';
import {ThemeProvider} from '@material-ui/styles';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {theme} from './theme';
import {configureStore} from './store';
import routes from './routes';
import ScrollReset from './components/ScrollReset';
import StylesProvider from './components/StylesProvider';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './mock';
import './assets/scss/main.scss';
import {SnackbarProvider} from "notistack";
import RouteBuilder from './RouteBuilder';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from './translations/en.json';
import de from './translations/de.json';
import { DEFAULT_LANGUAGE, loadLanguage } from './utils/language';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
        en: { translation: en },
        de: { translation: de }
    },
    lng: loadLanguage(),
    fallbackLng: DEFAULT_LANGUAGE,

    interpolation: {
      escapeValue: false
    }
  });

  
const history = createBrowserHistory();
const store = configureStore();

const App = () => (
    <StoreProvider store={store}>
        <ThemeProvider theme={theme}>
            <StylesProvider direction='ltr'>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <SnackbarProvider anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}>
                        <Router history={history}>
                            <ScrollReset/>
                            <RouteBuilder>
                                {renderRoutes(routes)}
                            </RouteBuilder>
                        </Router>
                    </SnackbarProvider>
                </MuiPickersUtilsProvider>
            </StylesProvider>
        </ThemeProvider>
    </StoreProvider>
);

export default App;
